import Vue from 'vue'
import VueRouter from 'vue-router'
import index from './index.vue'
import Preview from './Preview.vue'
import LongTranslate from './LongTranslate.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: index },
  { path: '/preview/:configId', component: Preview, props: true },
  { path: '/longTranslate', component: LongTranslate, props: true }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router