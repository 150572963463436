<template>
  <div class="preview">
    <VideoPreview
      ref="refVideoPreview"
      :audioUrl="config.audioUrl"
      :subtitles="config.subtitles"
      :config="config.config"
      :isRecording="true"
      @onEnded="onEnded"
    />
  </div>
</template>

<script>
import axios from "axios";
import FontFaceObserver from "fontfaceobserver";
import VideoPreview from "./VideoPreview.vue";
import { initRecordController, startRecord, stopRecord } from "dt-record-controller";

export default {
  components: {
    VideoPreview,
  },
  props: ["configId"],
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      config: { audioUrl: "" },
    };
  },
  methods: {
    loadFont(fontName) {
      return new Promise((resolve, reject) => {
        const fileName = fontName;
        //如果找到这个id了，说明加载过，就不再加载了
        //但假如同时有多个selectFontFamily组件在页面中，此刻只会在dom中插入一个link，那么第二个组件就直接resolve了
        //所以还需要给link加上载入完成标识
        if (document.querySelector(`#${fileName}`) && document.querySelector(`#${fileName}`).dataset.loaded == "true") {
          resolve();
          return;
        }
        //只在dom里没有的时候插入
        if (!document.querySelector(`#${fileName}`)) {
          const link = document.createElement("link");
          link.id = fileName;
          link.rel = "stylesheet";
          const fontBaseUrlDev = "/fonts";
          const fontBaseUrlProd = "//idtcdn.oss-cn-hangzhou.aliyuncs.com/txt2videoFonts";
          const fontBaseUrl = process.env.NODE_ENV === "development" ? fontBaseUrlDev : fontBaseUrlProd;
          link.href = `${fontBaseUrl}/${fileName}.css`;
          link.dataset.loaded = false;
          document.head.appendChild(link);
        }

        const font = new FontFaceObserver(fileName);
        //每个字体加载超时时间120秒
        font.load(null, 120 * 1000).then(
          function () {
            document.querySelector(`#${fileName}`).dataset.loaded = "true";
            resolve();
          },
          function () {
            const styleDom = document.querySelector(`#${fileName}`);
            if (styleDom) {
              styleDom.parentNode.removeChild(styleDom);
            }
            reject();
          }
        );
      });
    },
    sleep(seconds) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, seconds);
      });
    },
    onEnded() {
      stopRecord();
    },
  },
  created() {
    initRecordController();
  },
  async mounted() {
    try {
      const response = await axios.get(`${this.apiUrl}/api/get-configuration?configId=${this.configId}`);
      this.config = response.data;
      // console.log("this.config", this.config);
      await this.loadFont(this.config.config.font);
      await this.sleep(5 * 1000);
      await this.$refs.refVideoPreview.play();
      startRecord();
    } catch (error) {
      this.$message.error("获取配置失败: " + error.message);
    }
  },
};
</script>
