<template>
  <div class="the-app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  beforeUnmount() {},
  methods: {},
};
</script>

<style lang="less">
body{ margin: 0; padding: 0;}
</style>


