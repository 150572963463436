<template>
  <div class="long-translate">
    <div class="input-section">
      <textarea
        v-model="inputText"
        placeholder="Enter text to translate (max 10,000 characters)"
        :maxlength="10000"
      ></textarea>
    </div>
    <div class="button-section">
      <button @click="translateText" :disabled="isTranslating">
        {{ isTranslating ? 'Translating...' : 'Translate' }}
      </button>
    </div>
    <div class="output-section">
      <div v-for="(item, index) in translatedParagraphs" :key="index">
        <p>{{ item.original }}</p>
        <p>{{ item.translation }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LongTranslate',
  data() {
    return {
      inputText: '',
      translatedText: '',
      translatedParagraphs: [],
      isTranslating: false,
    };
  },
  methods: {
    async translateText() {
      if (!this.inputText) {
        return;
      }

      this.isTranslating = true;
      this.translatedText = '';
      this.translatedParagraphs = [];

      const paragraphs = this.inputText.split('\n\n');

      for (const paragraph of paragraphs) {
        const chunks = this.chunkText(paragraph, 5000);
        let translatedChunks = [];

        for (const chunk of chunks) {
          const requestBody = {
            model: 'qwen/Qwen2-72B-Instruct-GPTQ-Int4',
            messages: [
              {
                role: 'system',
                content: '你现在是一个翻译专家，将各种语言翻译成中文并输出。注意只需要翻译，不需要解读和解释，不需要添加任何额外的信息。',
              },
              {
                role: 'user',
                content: chunk,
              },
            ],
            stream: false,
          };

          try {
            const response = await axios.post('https://xy.wintaotel.com.cn/v1/chat/completions/v1/chat/completions', requestBody);
            const translatedChunk = response.data.choices[0].message.content;
            translatedChunks.push(translatedChunk);
          } catch (error) {
            console.error('Error:', error);
          }
        }

        const translatedParagraph = translatedChunks.join('');
        this.translatedParagraphs.push({ original: paragraph, translation: translatedParagraph });
      }

      this.isTranslating = false;
    },
    chunkText(text, chunkSize) {
      const chunks = [];
      const regexPattern = new RegExp(`.{1,${chunkSize}}`, 'g');
      const matches = text.match(regexPattern);
      if (matches) {
        chunks.push(...matches);
      }
      return chunks;
    },
  },
};
</script>

<style lang="less">
@import url("LongTranslate.less");
</style>