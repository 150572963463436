<template>
  <div :class="{ select_holder: true, expanded: isExpanded }" v-click-outside="cancelExpanded">
    <div :class="{ selected: true, is_loading: isDefaultFontLoading }" @click="selectTrigger">
      {{ currentFontFamilyCnName }}
    </div>
    <ul class="option_list">
      <li
        v-for="(item, index) in optionList"
        :key="index"
        :class="{ on: index == currentIndex, is_loading: item.isLoading }"
        @click="fontFamilyChange(item, index)"
      >
        {{ item.cnName }}
      </li>
    </ul>
  </div>
</template>
<script>
import FontFaceObserver from "fontfaceobserver";
import ClickOutside from "vue-click-outside";
import { cnOptionList, enOptionList } from "./selectFontFamily.js";

export default {
  name: "selectFontFamily",
  props: ["currentFontFamily", "type"],
  data() {
    return {
      isExpanded: false, //默认未展开
      isDefaultFontLoading: false, //传入默认字体时是loading状态
      optionList: [],
      currentFontFamilyCnName: "",
      currentIndex: 0, //当前项索引
      tempIndex: 0, //切换的时候最后一下的tempIndex
    };
  },
  directives: {
    ClickOutside,
  },
  watch: {
    currentFontFamily: {
      handler: async function (newVal) {
        this.currentIndex = this.getCurrentIndex(newVal);
        this.currentFontFamilyCnName = this.getNameObjByIndex(this.currentIndex).cnName;
        //变化的时候需要载入字体
        this.loadCurrentFont();
      },
    },
  },
  components: {},
  methods: {
    selectTrigger() {
      if (this.isDefaultFontLoading) {
        return;
      }
      this.isExpanded = !this.isExpanded;
    },
    cancelExpanded() {
      this.isExpanded = false;
    },
    //通过index找到名字对象
    getNameObjByIndex(index) {
      for (let i = 0; i < this.optionList.length; i++) {
        if (i === index) {
          return this.optionList[i];
        }
      }
      return "";
    },
    //通过当前英文名找到对应的index
    getCurrentIndex(currentFontFamily) {
      for (let i = 0; i < this.optionList.length; i++) {
        if (this.optionList[i].enName === currentFontFamily) {
          return i;
        }
      }
    },
    async fontFamilyChange(item, index) {
      this.tempIndex = index; //注意只是缓存下来了，这样不成功就不会赋值
      this.$set(item, "isLoading", true);
      try {
        await this.loadFont(item.enName);
        this.$set(item, "isLoading", false);
        this.currentIndex = this.tempIndex; //字体加载成功才会给currentIndex
        this.currentFontFamilyCnName = this.getNameObjByIndex(index).cnName;
        this.$emit("change", this.getNameObjByIndex(index).enName); //上报父组件change事件
      } catch (e) {
        this.$set(item, "isLoading", false);
        this.$message.error(this.getNameObjByIndex(index).cnName + "字体加载错误，请稍后重试！")
      }
    },
    //加载单个字体
    loadFont(fontName) {
      return new Promise((resolve, reject) => {
        const fileName = fontName;
        //如果找到这个id了，说明加载过，就不再加载了
        //但假如同时有多个selectFontFamily组件在页面中，此刻只会在dom中插入一个link，那么第二个组件就直接resolve了
        //所以还需要给link加上载入完成标识
        if (document.querySelector(`#${fileName}`) && document.querySelector(`#${fileName}`).dataset.loaded == "true") {
          resolve();
          return;
        }
        //只在dom里没有的时候插入
        if (!document.querySelector(`#${fileName}`)) {
          const link = document.createElement("link");
          link.id = fileName;
          link.rel = "stylesheet";
          const fontBaseUrlDev = "./fonts";
          const fontBaseUrlProd = "//idtcdn.oss-cn-hangzhou.aliyuncs.com/txt2videoFonts";
          const fontBaseUrl = process.env.NODE_ENV === "development" ? fontBaseUrlDev : fontBaseUrlProd;
          link.href = `${fontBaseUrl}/${fileName}.css`;
          link.dataset.loaded = false;
          document.head.appendChild(link);
        }

        const font = new FontFaceObserver(fileName);
        //每个字体加载超时时间120秒
        font.load(null, 120 * 1000).then(
          function () {
            document.querySelector(`#${fileName}`).dataset.loaded = "true";
            resolve();
          },
          function () {
            const styleDom = document.querySelector(`#${fileName}`);
            if (styleDom) {
              styleDom.parentNode.removeChild(styleDom);
            }
            reject();
          }
        );
      });
    },
   
    //加载当前的字体
    async loadCurrentFont() {
      this.isDefaultFontLoading = true;
      const currentEnName = this.getNameObjByIndex(this.currentIndex).enName;
      try {
        await this.loadFont(currentEnName);
        // this.$emit("change", currentEnName);
        this.isDefaultFontLoading = false;
      } catch (e) {
        this.$message.error(this.currentFontFamilyCnName + "字体加载错误！")
        this.isDefaultFontLoading = false;
      }
    },
  },
  async mounted() {
    this.optionList = this.type == "cn" ? cnOptionList : enOptionList;
    this.currentIndex = this.getCurrentIndex(this.currentFontFamily);
    this.currentFontFamilyCnName = this.getNameObjByIndex(this.currentIndex).cnName;

    this.loadCurrentFont();
  },
};
</script>
<style lang="less" scoped>
@import url("selectFontFamily.less");
</style>
