<template>
  <div class="color_chooser">
    <ul class="color_list">
      <li
        v-for="(item, index) in singleColorGroup"
        :style="`background-color:${item}`"
        :key="index"
        :class="{ on: item == innerColor }"
        @click="normalColorChange(item)"
      ></li>
    </ul>
    <span class="label label_change" v-if="['light','dark','thead','memo'].indexOf(colorTheme)==-1" @click="changeSingleColors"
      >换({{ groupIndex + 1 }}/3)</span
    >
    <tcColorPicker
      :class="{ custom_color: true, on: isCustomColor }"
      :customColor="customColor"
      @change="customColorChange"
    ></tcColorPicker>
  </div>
</template>
<script>
import { lightColors, darkColors,theadColors,memoColors, allColors, barColors } from "./colorChooser";
import tcColorPicker from "./tcColorPicker.vue";
const colorsObj = {
  "light": lightColors,
  "dark": darkColors,
  "thead": theadColors,
  "memo": memoColors,
  "all": allColors,
  "bar": barColors,
};
export default {
  name: "colorChooser",
  props: ["color", "colorTheme"],
  data() {
    return {
      innerColor: "",
      customColor: "#f4f7fd", //自定义颜色
      singleColorGroup: [],
      isCustomColor: false, //是否为用户自定义颜色
      groupIndex: 0, //第几组颜色
    };
  },
  watch: {
    color: {
      handler(newVal) {
        if (this.innerColor == newVal || newVal == undefined) {
          return;
        }
        this.innerColor = newVal;
        this.groupIndex = this.getGroupIndex(newVal);
        this.singleColorGroup = colorsObj[this.colorTheme][this.groupIndex];
        this.isCustomColor = this.judgeIfCustomColor();
        if (this.isCustomColor) {
          this.customColor = newVal;
        }
      },
      immediate: true,
      deep: false,
    },
  },
  components: { tcColorPicker },
  methods: {
    getGroupIndex(color) {
      let resultIndex = 0;
      colorsObj[this.colorTheme].map((group, index) => {
        if (group.indexOf(color) > -1) {
          resultIndex = index;
        }
      });
      return resultIndex;
    },
    //选择颜色，可能是单色或双色
    normalColorChange(val) {
      this.innerColor = val;
      this.isCustomColor = this.judgeIfCustomColor();
      this.$emit("colorChange", val);
    },
    //用户选择自定义色
    customColorChange(val) {
      this.customColor = val;
      this.innerColor = val;
      this.isCustomColor = this.judgeIfCustomColor();
      this.$emit("colorChange", this.customColor);
    },
    //换一组单色
    changeSingleColors() {
      let temp = this.groupIndex + 1;
      if (temp > 2) {
        temp = 0;
      }
      this.groupIndex = temp;
      this.singleColorGroup = colorsObj[this.colorTheme][this.groupIndex];
    },
    //判断是否为用户自定义颜色（单色）
    judgeIfCustomColor() {
      let isCustomColor = true;
      this.singleColorGroup.map((item) => {
        if (item == this.innerColor) {
          isCustomColor = false;
        }
      });
      return isCustomColor;
    },
  },
};
</script>
<style lang="less" scoped>
@import url("colorChooser.less");
</style>
