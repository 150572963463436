<template>
  <div class="holder">
    <div :class="{ 'video-container': true, 'is-recording': isRecording }" :style="containerStyle">
      <div class="subtitles-container" :style="subtitlesContainerStyle">
        <div
          v-for="(subtitle, index) in subtitles"
          :key="index"
          :class="`subtitle-line ${isSubtitleActive(index) ? 'active' : ''} ${
            subtitle.text.length > 16 ? 'txt_long2' : subtitle.text.length > 14 ? 'txt_long1' : ''
          }`"
          :style="getSubtitleStyle(index)"
          :ref="`subtitle-${index}`"
        >
          <span
            v-for="(char, charIndex) in subtitle.text.split('')"
            :key="`${index}-${charIndex}`"
            class="subtitle-char"
            :ref="`char-${index}-${charIndex}`"
          >
            {{ char }}
          </span>
        </div>
      </div>
      <div class="play-button-layer" @click="togglePlay">
        <div class="play-button">
          <i :class="isPlaying ? 'fas fa-stop' : 'fas fa-play'"></i>
        </div>
      </div>
    </div>
    <audio ref="audio" :src="audioUrl" @timeupdate="onTimeUpdate" @ended="onEnded"></audio>
  </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";

export default {
  props: {
    audioUrl: String,
    subtitles: Array,
    config: Object,
    isRecording: Boolean,
  },
  data() {
    return {
      currentIndex: -1,
      isPlaying: false,
    };
  },
  computed: {
    containerStyle() {
      return {
        fontFamily: this.config?.font || "Arial",
        justifyContent: this.config?.alignment == "left" ? "flex-start" : "center",
        backgroundImage:
          this.config?.background === "image" ? `url(${require(`@/assets/bgs/bg${this.config.backgroundImage}.jpg`)})` : "none",
        backgroundColor: this.config?.background === "color" ? this.config.backgroundColor : "transparent",
      };
    },
    subtitlesContainerStyle() {
      return {
        "text-align": this.config?.alignment,
      };
    },
  },
  methods: {
    togglePlay() {
      if (this.isPlaying) {
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
        this.currentIndex = -1;
      } else {
        this.$refs.audio.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    //录像时调用
    play() {
      this.$refs.audio.play();
      this.isPlaying = true;
    },
    onTimeUpdate() {
      const currentTime = this.$refs.audio.currentTime * 1000;
      const newIndex = this.subtitles.findIndex(
        (subtitle) => currentTime >= subtitle.startTime && currentTime <= subtitle.endTime
      );

      if (newIndex !== -1 && newIndex !== this.currentIndex) {
        this.currentIndex = newIndex;
        this.$nextTick(() => {
          this.animateSubtitle(this.$refs[`subtitle-${this.currentIndex}`][0], this.currentIndex);
        });
      }
    },

    animateSubtitle(element) {
      const chars = element.querySelectorAll(".subtitle-char");
      anime.remove(chars);
      const animationConfig = this.getAnimationConfig(this.config.animation);
      anime({
        targets: chars,
        ...animationConfig,
        begin: () => {
          element.style.opacity = 1;
        },
      });
    },

    getAnimationConfig(effect) {
      switch (effect) {
        case "oneLine":
          return {
            opacity: [0, 1],
            duration: 10,
            delay: anime.stagger(10),
          };
        case "typing1":
          return {
            opacity: [0, 1],
            duration: 50,
            // easing: 'easeInOutQuad',
            delay: anime.stagger(120),
          };
        case "typing2":
          return {
            opacity: [0, 1],
            duration: 200,
            // easing: 'easeInOutQuad',
            delay: anime.stagger(120),
          };
        case "typing3":
          return {
            opacity: [0.04, 1],
            duration: 300,
            easing: "steps(2)",
            delay: anime.stagger(120),
          };
        case "rightToLeft1":
          return {
            opacity: [0, 1],
            translateX: [50, 0],
            duration: 500,
            easing: "easeInOutQuad",
            delay: anime.stagger(65),
          };
        case "rightToLeft2":
          return {
            opacity: [0, 1],
            translateX: [80, 0],
            duration: 600,
            easing: "easeInOutCubic",
            delay: anime.stagger(65),
          };
        case "rightToLeft3":
          return {
            opacity: [0, 1],
            translateX: [150, 0],
            translateY: [50, 0],
            scale: [1.2, 1],
            duration: 550,
            rotateZ: ["-20deg", 0],
            perspective: "1200px",
            easing: "easeOutInSine",
            delay: anime.stagger(35),
          };
        case "rightToLeft4":
          return {
            opacity: [0, 1],
            translateX: [120, 0],
            translateY: [50, 0],
            scale: [1.65, 1],
            duration: 550,
            easing: "easeInOutCubic",
            delay: anime.stagger(45),
          };
        case "rightToLeft5":
          return {
            opacity: [0, 1],
            translateX: [80, 0],
            translateY: [20, 0],
            scale: [0.8, 1],
            duration: 550,
            easing: "easeInOutExpo",
            delay: anime.stagger(45),
          };
        default:
          return {
            opacity: [0, 1],
            duration: 600,
            easing: "linear",
            delay: anime.stagger(30),
          };
      }
    },
    onEnded() {
      this.isPlaying = false;
      this.$refs.audio.currentTime = 0;
      this.currentIndex = -1;
      this.$emit("onEnded");
    },
    isSubtitleActive(index) {
      return index <= this.currentIndex && index > this.currentIndex - this.config.maxLines;
    },
    getSubtitleStyle(index) {
      const isActive = this.isSubtitleActive(index);
      return {
        "min-height": isActive ? "1.5em" : "0",
        height: isActive ? "1.5em" : "0",
        opacity: isActive ? 1 : 0,
      };
    },
  },
};
</script>

<style scoped lang="less">
.holder {
  .video-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 1080px;
    height: 1920px;
    transform: scale(0.4);
    transform-origin: top left;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    &.is-recording {
      position: relative;
      transform: none;
      .play-button-layer {
        display: none;
      }
    }
    .play-button-layer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      cursor: pointer;
    }
    &:hover .play-button {
      opacity: 0.8;
    }
  }
  .subtitles-container {
    color: white;
    padding: 10px;
    border-radius: 5px;
    overflow: hidden;
    line-height: 1.5;
    font-size: 83px;
    display: flex;
    flex-direction: column;
  }
  .subtitle-line {
    overflow: hidden;
    transition: all 0.4s ease;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.05);
    opacity: 0;
    &.txt_long1 .subtitle-char {
      font-size: 79px;
    }
    &.txt_long2 .subtitle-char {
      font-size: 65px;
    }
    .subtitle-char {
      display: inline-block;
      opacity: 0;
      background: url("./assets/txtBgs/txt_bg1.png");
      background-size: cover;
      background-clip: text;
      color: transparent;
    }
  }
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    color: white;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s;
  }
}

@media (max-width: 768px) {
  .holder {
    .video-container {
      transform: scale(0.32);
    }
  }
}
</style>
