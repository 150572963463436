export const lightColors=[
  ['#ffffff','#EBF3FF','#EFE7F2','#EDF7F6','#EBF5EA','#EFDDDC','#F8F5ED','#EBE8E2'],
]
export const darkColors=[
  ['#000000','#333','#8e3719','#a99212','#26b34c','#a42780'],
]
export const theadColors=[
  ['#1C2431','#203E21','#47384D','#4350A8','#CC3126','#266430','#936A40','#50546C'],
]
export const memoColors=[
  ['#999999','#666666','#333333','#aaaaaa','#bbbbbb','#cccccc','#eeeeee','#efefef'],
]
export const allColors=[
  ['#000000','#45312C','#2434A3','#4A5089','#F8F9FF','#ced2f6','#F7F7EF'],
  ['#16161D','#47384D','#2C4534','#6F3115','#E2E6ED','#D8D3EA','#F0C3B3'],
  ['#CC3126','#966328','#4E3B20','#266430','#B2B2F4','#E6D3EA','#A2DEC3'],
]
export const barColors=[
  ['#2434A3','#222A36','#45312C','#4A5089','#DB2533','#2EB7A2','#FCA835'],
  ['#008080','#16161D','#2C4534','#6F3115','#E37B44','#374FEF','#F05D3B'],
  ['#EB281A','#966328','#4E3B20','#266430','#602EE8','#EA21BB','#38A824'],
]

/*随机生成单色
colorCount 是生成颜色的个数
colorTheme 可能为dark，light
givenColor 是给定的颜色，放在结果数组的第一位
*/
export function generateSingleColors(colorCount, colorTheme, givenColor) {
  const colors = [];
  let availableThemes = [];
  if (colorTheme === "all") {
    availableThemes = ["dark", "light"];
  } else {
    availableThemes = [colorTheme ? colorTheme : "dark"];
  }
  if (givenColor) {
    colors.push(givenColor);
    colorCount--;
  }
  for (let i = 0; i < colorCount; i++) {
    const currentTheme = availableThemes[Math.floor(Math.random() * availableThemes.length)];
    let h, s, l;
    if (currentTheme === "dark") {
      h = Math.floor(Math.random() * 360);
      s = Math.floor(Math.random() * 100);
      l = Math.floor(Math.random() * 50);
    } else {
      h = Math.floor(Math.random() * 360);
      s = Math.floor(Math.random() * 50);
      l = Math.floor(Math.random() * 11) + 90;
    }
    const color = hslToHex(h, s, l);
    if (color !== givenColor && !colors.includes(color)) {
      colors.push(color);
    } else {
      i--; // 重新生成一个颜色
    }
  }
  return colors;
}
// hsl转hex
function hslToHex(h, s, l) {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = Math.round(hue2rgb(p, q, h + 1 / 3) * 255);
    g = Math.round(hue2rgb(p, q, h) * 255);
    b = Math.round(hue2rgb(p, q, h - 1 / 3) * 255);
  }
  return rgbToHex(r, g, b);
}
// rgb转hex
function rgbToHex(r, g, b) {
  const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

//随机生成渐变色
export function generateGradientColors(colorCount,givenColor) {
  const gradientColors = [
    "#F86BDF-#6B6BF8",
    "#B86BF8-#6BB5F8",
    "#FC8E51-#FA61F9",
    "#FC466B-#3F5EFB",
    "#D24074-#1268C3",
    "#9C7CF4-#F49782",
    "#A1FF8B-#3F93FF",
    "#172368-#E249AF",
    "#6964DE-#FCA6E9",
    "#4B55DB-#34E0E0",
    "#B0FF4B-#11876B",
    "#7D2DFF-#41DDFF",
    "#f093fb-#f5576c",
    "#FFCA58-#8B49FF",
    "#FDBD55-#F983FF",
    "#6F2370-#EE4BF0",
    "#3378FF-#9442FE",
    "#BDC3C7-#2C3E50",
    "#FF0000-#FDCF58",
    "#7EC6BC-#EBE717",
    "#59CDE9-#0A2A88",
    "#FDC830-#F37335",
    "#fc00ff-#00dbde",
    "#FF8570-#418CB7",
    "#00E0EE-#AD00FE",
    "#D7003A-#19087E",
    "#FF0000-#470000",
    "#FFF500-#FF00B8",
    "#FF5EEF-#456EFF",
    "#FFC328-#E20000",
    "#FF896D-#4300B1",
    "#61C695-#133114",
    "#FF68C0-#6854F9",
  ];
  if (colorCount > gradientColors.length) {
    throw new Error(
      `Color count cannot be greater than ${gradientColors.length}`
    );
  }
  const colors = [];
  if (givenColor!=undefined) {
    colors.push(givenColor);
    colorCount--;
  }
  while (colors.length < colorCount) {
    const index = Math.floor(Math.random() * gradientColors.length);
    const color = gradientColors[index];
    if (!colors.includes(color)) {
      colors.push(color);
    }
  }
  return colors;
}
