<template>
  <div>
      <div class="tutorial">
          <h3>快速教程</h3>
          <div class="tutorial-steps">
            <div class="step">
              <div class="step-number">1</div>
              <div class="step-content">
                <h4>输入文本</h4>
                <p>在左侧输入框中输入您希望转换为语音的文本内容。</p>
              </div>
            </div>
            <div class="step">
              <div class="step-number">2</div>
              <div class="step-content">
                <h4>选择声音</h4>
                <p>在右侧选择您喜欢的声音类别和具体声音。</p>
              </div>
            </div>
            <div class="step">
              <div class="step-number">3</div>
              <div class="step-content">
                <h4>开始转换</h4>
                <p>点击"开始转换"按钮,即可生成语音文件。</p>
              </div>
            </div>
          </div>
        </div>
        <div class="user-comments">
          <h3>用户寄语</h3>
          <div class="comment">
            <!-- <img src="./assets/avatar1.png" alt="User Avatar" /> -->
            <div class="comment-content">
              <p>"这个网站太棒了!我终于可以轻松地将文本转换为语音,非常方便。"</p>
              <span>- 李先生</span>
            </div>
          </div>
          <div class="comment">
            <!-- <img src="./assets/avatar2.png" alt="User Avatar" /> -->
            <div class="comment-content">
              <p>"语音质量真的很棒,听起来就像真人在朗读一样自然流畅。强烈推荐!"</p>
              <span>- 张女士</span>
            </div>
          </div>
        </div>
        <div class="faq">
          <h3>常见问题</h3>
          <div class="question">
            <h4>合成语音的质量如何?</h4>
            <p>我们使用了先进的语音合成技术,可以提供接近真人的自然流畅语音。</p>
          </div>
          <div class="question">
            <h4>是否支持多种语言?</h4>
            <p>目前仅支持中文,我们正在努力添加更多语言。</p>
          </div>
          <div class="question">
            <h4>合成后的语音文件格式是什么?</h4>
            <p>我们提供 MP3 格式的语音文件,方便您在各种场合使用。</p>
          </div>
        </div>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  computed: {},
  mounted() {},

  methods: {},
};
</script>


