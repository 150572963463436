<template>
  <div class="video-config">
    <el-form :model="localConfig" label-width="100px">
      <el-form-item label="字体">
        <select-font-family type="cn" :currentFontFamily="localConfig.font" @change="titleFontFimilyChange" />
      </el-form-item>
      <el-form-item label="动画效果">
        <el-select v-model="localConfig.animation" placeholder="请选择动画效果">
          <el-option label="单行直出" value="oneLine"></el-option>
          <el-option label="打字效果1" value="typing1"></el-option>
          <el-option label="打字效果2" value="typing2"></el-option>
          <el-option label="打字效果3" value="typing3"></el-option>
          <el-option label="从右往左效果1" value="rightToLeft1"></el-option>
          <el-option label="从右往左效果2" value="rightToLeft2"></el-option>
          <el-option label="从右往左效果3" value="rightToLeft3"></el-option>
          <el-option label="从右往左效果4" value="rightToLeft4"></el-option>
          <el-option label="从右往左效果5" value="rightToLeft5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="水平对齐方式">
        <el-select v-model="localConfig.alignment" placeholder="请选择对齐方式">
          <el-option label="居左" value="left"></el-option>
          <el-option label="居中" value="center"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最多几行文字">
        <el-select v-model="localConfig.maxLines" placeholder="请选择最多行数">
          <el-option v-for="i in 6" :key="i" :label="i" :value="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="背景">
        <el-radio-group v-model="localConfig.background">
          <el-radio label="color">纯色</el-radio>
          <el-radio label="image">背景图</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="localConfig.background === 'color'" label="背景颜色">
        <color-chooser :color="localConfig.backgroundColor" colorTheme="dark" @colorChange="colorChange" />
      </el-form-item>
      <el-form-item v-if="localConfig.background === 'image'" label="背景图片">
        <div class="background-selector">
          <div
            v-for="i in 5"
            :key="i"
            class="background-option"
            :class="{ active: localConfig.backgroundImage === i }"
            @click="selectBackground(i)"
          >
            <img :src="getBackgroundImageUrl(i)" :alt="`背景图 ${i}`" />
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSubmitRecording" :loading="isLoading">
          提交录屏
        </el-button>
        <span v-if="!isMask" class="remaining-submissions">今日剩余次数: {{ remainingSubmissions }}</span>
      </el-form-item>
      <p v-if="isLoading">录屏状态: {{ taskStatusTxt }}</p>
      <p v-if="resultUrl">视频地址：<a :href="resultUrl" target="_blank">查看</a></p>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";
import selectFontFamily from "./selectFontFamily/selectFontFamily.vue";
import colorChooser from "./colorChooser/colorChooser.vue";

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      innerFontFamily: "",
      localConfig: { ...this.value },
      backgroundImages: [
        require("@/assets/bgs/bg1.jpg"),
        require("@/assets/bgs/bg2.jpg"),
        require("@/assets/bgs/bg3.jpg"),
        require("@/assets/bgs/bg4.jpg"),
        require("@/assets/bgs/bg5.jpg"),
      ],
      taskStatusTxt: "",
      resultUrl: "",
      isLoading: false,
      isMask: false,
      remainingSubmissions: 3,
    };
  },
  components: {
    selectFontFamily,
    colorChooser,
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.isMask = urlParams.get('isMask') === 'true';
    if (!this.isMask) {
      this.updateRemainingSubmissions();
    }
  },
  watch: {
    localConfig: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
  },
  methods: {
    titleFontFimilyChange(newVal) {
      this.localConfig.font = newVal;
    },
    colorChange(val) {
      this.localConfig.backgroundColor = val;
    },
    selectBackground(index) {
      this.localConfig.backgroundImage = index;
    },
    getBackgroundImageUrl(index) {
      return this.backgroundImages[index - 1];
    },

    updateRemainingSubmissions() {
      const today = new Date().toDateString();
      const uid = this.$parent.uid;
      const quotaKey = `submissionQuota_${uid}_${today}`;
      let quota = localStorage.getItem(quotaKey);
      this.remainingSubmissions = quota ? parseInt(quota) : 2;
    },

    handleSubmitRecording() {
      if (this.isMask || this.checkSubmissionQuota()) {
        this.submitRecording();
      } else {
        this.$message.error("今日提交次数已用完，请明天再试。");
      }
    },

    checkSubmissionQuota() {
      const today = new Date().toDateString();
      const uid = this.$parent.uid;
      const quotaKey = `submissionQuota_${uid}_${today}`;
      let quota = localStorage.getItem(quotaKey);

      if (!quota) {
        localStorage.setItem(quotaKey, '2');
        this.remainingSubmissions = 2;
        return true;
      }

      quota = parseInt(quota);
      if (quota > 0) {
        localStorage.setItem(quotaKey, (quota - 1).toString());
        this.remainingSubmissions = quota - 1;
        return true;
      }

      this.remainingSubmissions = 0;
      return false;
    },

    async submitRecording() {
      try {
        this.isLoading = true;
        const response = await axios.post(`${this.apiUrl}/api/submit-configuration`, {
          uid: this.$parent.uid,
          audioUrl: this.$parent.synthesizedAudioUrl,
          subtitles: this.$parent.subtitles,
          config: this.localConfig,
        });
        const configId = response.data.configId;
        this.sendTask(configId);
      } catch (error) {
        this.isLoading = false;
        this.$message.error("提交配置失败: " + error.message);
      }
    },

    async sendTask(configId) {
      try {
        const url = `${location.origin}/preview/${configId}`;
        let task = {
          priority: 1,
          record_url: url,
          resolution: 1,
          fps: 60,
          api: "",
          sentTime: new Date().toLocaleString(),
          video_duration: 30 * 60,
          is_portrait: true,
        };

        const workerUrl = `${this.apiUrl}/transfer`;
        const targetUrl = "http://recorder-test.wedengta.com/api/server/obsRecordTask/create";

        const { data } = await axios.post(workerUrl, {
          targetUrl: targetUrl,
          ...task,
        });

        console.log("data", data);

        if (data.code === 200) {
          this.getVideoResult(data.data);
        }
      } catch (error) {
        this.isLoading = false;
        this.$message.error("提交录屏失败: " + error.message);
      }
    },

    async getVideoResult(taskId) {
      try {
        const workerUrl = `${this.apiUrl}/transfer`;
        const targetUrl = "http://recorder-test.wedengta.com/api/server/obsRecordTask/query";

        const { data } = await axios.post(workerUrl, {
          targetUrl: targetUrl,
          task_id: taskId,
        });

        if (data.code === 200) {
          this.taskStatusTxt = this.getTaskStatusTxt(data.data.task_status);
          if (data.data.task_status === 2) {
            this.$message.success("录屏成功");
            this.resultUrl = data.data.oss_address;
            this.isLoading = false;
          } else if (data.data.task_status === -1) {
            this.$message.error("录屏失败");
            this.resultUrl = "录屏失败";
            this.isLoading = false;
          } else {
            setTimeout(() => {
              this.getVideoResult(taskId);
            }, 5000);
          }
        }
      } catch (error) {
        console.error("获取视频结果失败:", error);
        this.$message.error("获取视频结果失败: " + error.message);
        this.isLoading = false;
      }
    },

    getTaskStatusTxt(status) {
      if (status == -1) {
        return "失败";
      } else if (status == 0) {
        return "未开始录屏，排队中，请不要刷新，否则进度将丢失";
      } else if (status == 1) {
        return "已开始录屏";
      } else if (status == 2) {
        return "录屏成功";
      }
    },
  },
};
</script>

<style scoped lang="less">
.video-config {
  width: 400px;
}

.el-form-item {
  margin-bottom: 10px;
}

.background-selector {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.background-option {
  width: 60px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid transparent;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  &.active {
    border: 2px solid #409eff;
    transform: scale(1.05);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.remaining-submissions {
  margin-left: 10px;
  font-size: 14px;
  color: #606266;
}
</style>