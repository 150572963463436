<template>
  <div class="subtitles-timeline">
    <div v-for="(subtitle, index) in subtitles" :key="index" class="subtitle-item">
      <div class="subtitle-text">{{ subtitle.text }}</div>
      <div class="subtitle-time">
        <span>{{ formatTime(subtitle.startTime) }}</span>
        <span> - </span>
        <span>{{ formatTime(subtitle.endTime) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subtitles: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatTime(milliseconds) {
      const seconds = milliseconds / 1000;
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    },
  },
};
</script>

<style scoped lang="less">
.subtitles-timeline {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;

  .subtitle-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;

    &:last-child {
      border-bottom: none;
    }

    .subtitle-text {
      flex: 1;
      margin-right: 10px;
    }

    .subtitle-time {
      font-size: 0.9em;
      color: #666;
    }
  }
}
</style>
