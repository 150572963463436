<template>
  <toolcool-color-picker :id="id" class="tc_color_picker" :color="customColor" popup-position="right"></toolcool-color-picker>
</template>
<script>
import "toolcool-color-picker";
export default {
  name: "tcColorPicker",
  props: ["customColor"],
  data() {
    return {
      id: 0,
    };
  },
  watch: {
    value: {
      handler() {
        // console.log("newVal", newVal);
      },
    },
  },
  components: {},
  methods: {
    randomNum(minNum, maxNum) {
      switch (arguments.length) {
        case 1:
          return parseInt(Math.random() * minNum + 1, 10);
        case 2:
          return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
        default:
          return 0;
      }
    },
  },
  created() {
    this.id = "id" + new Date().getTime() + this.randomNum(100, 500);
  },
  mounted() {
    const tcColorPicker = document.getElementById(this.id);
    tcColorPicker.addEventListener("change", (event) => {
      this.$emit("change", event.detail.rgba);
    });
  },
};
</script>
<style lang="less" scoped>
.tc_color_picker {
  display: inline-block;
}
</style>
