const cnOptionList = [
  {
    enName: "DeYiHei",
    cnName: "得意黑",
  },
  {
    enName: "MiaoHei",
    cnName: "仓迹高德国妙黑",
  },
  {
    enName: "Leefont",
    cnName: "乐字蒙黑体",
  },
  {
    enName: "YanShiXieHeiTi",
    cnName: "演示斜黑体",
  },
  {
    enName: "JiangChengLvDongHei",
    cnName: "江城律动黑",
  },
  {
    enName: "XiangLiFangHeiTi",
    cnName: "乡立方黑体",
  },
  {
    enName: "BianTaoTi",
    cnName: "扁桃体",
  },
  {
    enName: "XiaWuManHei",
    cnName: "霞鹜漫黑",
  },
  {
    enName: "HanChanYouJiTi",
    cnName: "寒蝉有机体",
  },
  {
    enName: "YouSheBiaoTiYuan",
    cnName: "优设标题圆",
  },
  {
    enName: "YanShiYouRanXiaoKai",
    cnName: "演示悠然小楷",
  },
  {
    enName: "YuanSaiYaKai",
    cnName: "芫茜雅楷",
  },
  {
    enName: "ChengRongGuangKeKai",
    cnName: "程荣光刻楷",
  },
  
];

const enOptionList = [
  {
    enName: "OPPOSans",
    cnName: "OPPOSans",
  },
  {
    enName: "OPPOSans-Bold",
    cnName: "OPPOSans Bold",
  },
  {
    enName: "Alibaba-Puhuiti",
    cnName: "阿里巴巴普惠体",
  },
  {
    enName: "Harmony",
    cnName: "鸿蒙黑体",
  },
  {
    enName: "Harmony-Bold",
    cnName: "鸿蒙黑体粗",
  },
  {
    enName: "Alimama-Fangyuan",
    cnName: "阿里妈妈方圆",
  },
  {
    enName: "Alimama-Shuheiti",
    cnName: "阿里妈妈数黑",
  },
  {
    enName: "Youshe-Haoshenti",
    cnName: "优设好身体",
  },
  {
    enName: "Jinshanyun-Jishu",
    cnName: "金山云技术体",
  },
  {
    enName: "Siyuan-Song",
    cnName: "思源宋体",
  },
  {
    enName: "Blender",
    cnName: "Blender",
  },
  {
    enName: "Archivo",
    cnName: "Archivo",
  },
  {
    enName: "Chivo",
    cnName: "Chivo",
  },
  {
    enName: "Abel",
    cnName: "Abel",
  },
  {
    enName: "Gats",
    cnName: "Gats",
  },
  {
    enName: "Akrobat",
    cnName: "Akrobat",
  },
  {
    enName: "Poppins",
    cnName: "Poppins",
  },
  {
    enName: "D-Din",
    cnName: "D-Din",
  },
];

export { cnOptionList, enOptionList };
