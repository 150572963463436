<template>
    <div class="realtime-subtitles">
      <span v-for="(line, index) in displayedLines" :key="index" class="subtitle-line">{{line}}</span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'RealtimeSubtitles',
    props: {
      subtitles: {
        type: Array,
        required: true
      },
      audioStartTime: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        displayedLines: [],
        currentIndex: 0
      }
    },
    watch: {
      subtitles: {
        handler(newSubtitles) {
          this.processSubtitles(newSubtitles)
        },
        deep: true
      }
    },
    methods: {
      processSubtitles(subtitles) {
        if (subtitles.length === 0) return
  
        const currentTime = Date.now() - this.audioStartTime
        while (this.currentIndex < subtitles.length && subtitles[this.currentIndex].BeginTime <= currentTime) {
          this.displaySubtitle(subtitles[this.currentIndex].Text)
          this.currentIndex++
        }
  
        // 继续检查后续字幕
        if (this.currentIndex < subtitles.length) {
          setTimeout(() => this.processSubtitles(subtitles), 50)
        }
      },
      displaySubtitle(text) {
        this.displayedLines.push(text)
      }
    }
  }
  </script>
  
  <style scoped>
  .realtime-subtitles {
    font-size: 24px;
    text-align: left;
    margin-top: 20px;
    line-height: 1.5;
  }
  
  .subtitle-line {
    display: inline;
  }
  
  .subtitle-line:after {
    content: " ";
  }
  </style>